import { IUserStore } from './user-store.model';

export class SetUser {
  static readonly type = '[USER] set user';
  constructor(
    public payload: IUserStore
  ) { }
}

export class ResetUser {
  static readonly type = '[USER] reset user';
  constructor() { }
}
