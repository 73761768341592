import { Component } from '@angular/core';
import { AppService } from './app.service';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'chow-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public appService: AppService
  ) { }
}
