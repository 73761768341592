import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [{
  path: 'sign-in',
  loadChildren: () => import('./pages/auth/sign-in/sign-in.module')
    .then((m) => m.SignInModule)
}, {
  path: '',
  loadChildren: () => import('./core/layout/layout.module')
    .then((m) => m.LayoutModule),
  data: { animations: 'CreatePage' }
}, {
  path: '**',
  redirectTo: '404',
  pathMatch: 'full'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
