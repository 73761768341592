import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private loading = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private snackbar: MatSnackBar
  ) {
    try {
      this.router.events
        .subscribe((event: RouterEvent) => {
          switch (true) {
            case event instanceof NavigationStart: {
              this.loading.next(true);
              break;
            }
            case event instanceof NavigationError: {
              this.snackbar.open('Navigation failed. Please check your Internet connection or call your system administrator');
              this.loading.next(false);
              break;
            }
            case event instanceof NavigationCancel:
            case event instanceof NavigationEnd: {
              this.loading.next(false);
              break;
            }
          }
        });
    } catch (e) {
      console.error(e);
      this.loading.next(false);
    }
  }

  public get isLoading(): boolean {
    return this.loading.value;
  }

  public showProgressBar(): void {
    this.loading.next(true);
  }

  public hideProgressBar(): void {
    this.loading.next(false);
  }

}
