// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyALdQj-rQWMjJop2QyG6VaXW8p5aY7u66o',
    authDomain: 'chowin-dev.firebaseapp.com',
    databaseURL: 'https://chowin-dev.firebaseio.com',
    projectId: 'chowin-dev',
    storageBucket: 'chowin-dev.appspot.com',
    messagingSenderId: '677460809815',
    appId: '1:677460809815:web:725f03bdc9b2bcc8ad2b12',
    measurementId: 'G-F7QY1SB478'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
